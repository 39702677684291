import "../App.css"; // Ensure your CSS is linked
import React, { useState, useEffect } from "react";

function GlassContainer({ theme, children, style, disabled }) {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768); // Adjust the threshold as needed

  const handleResize = () => {
    const isCurrentlyMobile = window.innerWidth < 768;
    setIsMobile(isCurrentlyMobile);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    (!isMobile && (
      <div
        className="frosted-glass-container-blur-plus-plus"
        style={{
          border: "1px solid #262e59",
          display: "flex",
        //   flexDirection: "row",
          width: "80%",
          justifyContent: "center",
          alignItems: "center",

          ...style
        }}
      >
        
          {children} {/* Render the children */}
        
      </div>
    )) || (
      <div
        className={disabled ? null : "frosted-glass-container-blur-plus-plus"}
        style={{
          border: disabled ? null : "1px solid #262e59",
          display: "flex",
          flexDirection: "column",
          width: "100%",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          marginBottom: 15,
          borderRadius: 0,
          ...style
        }}
      >
        <div style={{ width: "100%" }}>
          {children} {/* Render the children */}
        </div>
      </div>
    )
  );
}

export default GlassContainer;
