// AnimatedIconButton.js
import "../App.css"; // Ensure your CSS is linked
import React, { useState, useEffect } from "react";
import moment from "moment/moment";
import { FaReact } from "react-icons/fa";
import { FaPython } from "react-icons/fa";
import { FaJava } from "react-icons/fa";

function ProjectItem({ theme, style, title, description, technologies, icon, sourceCodeAvailable, url, onClick }) {

  const [isHovered, setIsHovered] = useState(false); // New state for hover
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768); // Adjust the threshold as needed



  const handleResize = () => {
    const isCurrentlyMobile = window.innerWidth < 768;
    setIsMobile(isCurrentlyMobile);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const techStyles = {
    react_js: {
      border: '2px solid #61DAFB',
      backgroundColor: '#23272D',
      color: '#61DAFB',
    },
    react_native: {
      border: '2px solid #61DAFB',
      backgroundColor: '#23272D',
      color: '#61DAFB',
    },
    node_js: {
      border: '2px solid #5FA04E',
      backgroundColor: '#23272D',
      color: '#5FA04E',
    },
    javascript: {
      border: '2px solid #F0DB4F',
      backgroundColor: '#323330',
      color: '#F0DB4F',
    },
    python: {
      border: '2px solid #306998',
      backgroundColor: '#FFE873',
      color: '#306998',
    },
    html: {
      border: '2px solid #E34F26',
      backgroundColor: '#F06529',
      color: '#E34F26',
    },
    css: {
      border: '2px solid white',
      backgroundColor: '#2965F1',
      color: 'white',
    },
    php: {
      border: '2px solid #8993be',
      backgroundColor: '#4F5B93',
      color: '#8993be',
    },
    mongodb: {
      border: '2px solid #4DB33D',
      backgroundColor: '#23272D',
      color: '#4DB33D',
    },
    firebase: {
      border: '2px solid #FFCA28',
      backgroundColor: '#23272D',
      color: '#FFCA28',
    },
    stripe: {
      border: '2px solid #6772E5',
      backgroundColor: '#23272D',
      color: '#6772E5',
    },
    aws_s3: {
      border: '2px solid #FF9900',
      backgroundColor: '#23272D',
      color: '#FF9900',
    },
    digital_ocean: {
      border: '2px solid #0080FF',
      backgroundColor: '#23272D',
      color: '#0080FF',
    },
    nginx: {
      border: '2px solid #009639',
      backgroundColor: '#23272D',
      color: '#009639',
    },
    keystone: {
      border: '2px solid #3D7587',
      backgroundColor: '#23272D',
      color: '#3D7587',
    },
    twilio: {
      border: '2px solid #F22F46',
      backgroundColor: '#23272D',
      color: '#F22F46',
    },
    pm2: {
      border: '2px solid #2CA536',
      backgroundColor: '#23272D',
      color: '#2CA536',
    },
    websocket: {
      border: '2px solid #888888',
      backgroundColor: '#23272D',
      color: '#888888',
    },
    express_js: {
      border: '2px solid #303030',
      backgroundColor: '#23272D',
      color: '#303030',
    },
    gradle: {
      border: '2px solid #02303A',
      backgroundColor: '#23272D',
      color: '#02303A',
    },
    ruby: {
      border: '2px solid white',
      backgroundColor: '#B71302',
      color: 'white',
    },
  };

  return (
    (!isMobile === true && (
      
      <div
        className={`non-highlightable ${isHovered ? 'hovered' : ''}`} // Apply the hovered class
        onMouseEnter={() => setIsHovered(true)} // Set hover state to true
        onMouseLeave={() => setIsHovered(false)} // Set hover state to false
        onClick={() => onClick ? onClick() : null}
        // className="frosted-glass-container-blur-plus-plus"
        style={{
          height: 260,
          width: 340,
          border: isHovered ? "1px solid #98FF98" : "1px solid #6e6ef4",
          borderRadius: 15,
          ...style,
          display: "flex",
          flexDirection: "column", // Keeps children in a column
          backgroundColor: '#120922',
          background: isHovered ? 'linear-gradient(180deg, rgba(28,19,57,1) 0%, rgba(14,14,33,0.7) 52%, rgba(28,19,57,1) 94%)'  : 'linear-gradient(0deg, rgba(58,19,110,.7) 0%, rgba(14,14,33,0.7) 82%, rgba(28,19,57,1) 94%)',
          boxShadow: "0 4px 20px rgba(0, 0, 0, 0.5)", // Add drop shadow
          // width: '100%', // Ensure it fills the parent container,
          margin: 15,
          cursor: 'pointer',
         
        }}
      >

     

          <div
            style={{height: '25%', padding: 15, display: 'flex', flexDirection: 'row', }}
          >
            <div
              style={{flex: 8, padding: 0, display: 'flex', justifyContent: 'flex-start', alignItems: 'center', overflow: 'hidden', textOverflow: 'ellipsis',}}
            > 
              <h1 className="bolden-gate" style={{paddingLeft: 15, fontSize: 22,  color: 'white'}}>{title}</h1>
            </div>
            <div
              style={{flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', }}
            > 
              {icon === 'react_native' && (
                <FaReact color={theme === 'dark' ? '#ccccff' : '#5a5ac9'} size={40} /> 
              )
              ||
              icon === 'react' && (
                <FaReact color={theme === 'dark' ? '#ccccff' : '#5a5ac9'} size={40} /> 
              )
              ||
              icon === 'python' && (
                <FaPython color={theme === 'dark' ? '#ccccff' : '#5a5ac9'} size={40} /> 
              )
              ||
              icon === 'java' && (
                <FaJava color={theme === 'dark' ? '#ccccff' : '#5a5ac9'} size={40} /> 
              )}
              
            </div>
          </div>

          <div
            style={{
              height: '35%',
              padding: 15,
              paddingTop: 0,
              display: 'flex',
              flexDirection: 'row',
              overflow: 'hidden',
              // border:"1px solid white"
            }}
          >
            <div
              style={{
                flex: 1,
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
              }}
            >
              <p
                style={{
                  paddingLeft: 15,
                  textAlign: 'left',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',        // This enables multi-line ellipsis
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 3,            // Limits the text to 2 lines
                  lineHeight: '1.5em',           // Adjust line height to ensure neat line spacing
                  maxHeight: '5em',              // Adjust max height to accommodate 2 lines
                  whiteSpace: 'normal',          // Allows text to wrap
                }}
              >
                {description}
              </p>
            </div>
          </div>





        <div
          style={{
            fontSize: 13,
            height: '30%',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            paddingLeft: 20,
            overflow: 'visible'
          }}
        >
          <div
            style={{
              width: '100%',
              // border: '1px solid white',
              padding: 5,
              flexWrap: 'wrap',
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              
            }}
          >
            {technologies && technologies.length > 0 && (
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {technologies.slice(0, 4).map((tech, index) => {
                  const techStyle = techStyles[tech.value];
                  const shadowColor = techStyle ? techStyle.color : 'white'; // Use tech color or default to white
                  return techStyle ? (
                    <div
                      key={index}
                      className="bolden-gate"
                      style={{
                        display: 'inline-block',
                        border: techStyle.border,
                        borderRadius: 15,
                        padding: '3px 10px',
                        margin: '5px',
                        whiteSpace: 'nowrap',
                        textAlign: 'left',
                        backgroundColor: techStyle.backgroundColor,
                        color: techStyle.color,
                        boxShadow: `0 1px 10px ${shadowColor}, 0 2px 10px rgba(0, 0, 0, 0.3)`, // Shadow same as tech color
                      }}
                    >
                      {tech.language}
                    </div>
                  ) : (
                    <div
                      key={index}
                      className="bolden-gate"
                      style={{
                        display: 'inline-block',
                        border: '2px solid white',
                        borderRadius: 15,
                        padding: '3px 10px',
                        margin: '5px',
                        whiteSpace: 'nowrap',
                        textAlign: 'left',
                        backgroundColor: 'black',
                        color: 'white',
                        boxShadow: `0 1px 10px white`, // White shadow for the fallback
                      }}
                    >
                      {tech.language}
                    </div>
                  );
                })}
                {technologies.length > 4 && (
                  <div
                    className="bolden-gate"
                    style={{
                      display: 'inline-block',
                      border: '2px solid #6e6ef4',
                      borderRadius: 15,
                      padding: '3px 10px',
                      margin: '5px',
                      whiteSpace: 'nowrap',
                      textAlign: 'left',
                      backgroundColor: 'black',
                      color: 'white',
                      boxShadow: `0 1px 10px white`, // White shadow for the "+ x more"
                    }}
                  >
                    + {technologies.length - 4} more
                  </div>
                )}
              </div>
            )}


          </div>
        </div>
          <div
            style={{height:"17%", display: 'flex', flexDirection: 'row', }}
          >
            <div
              style={{
                width: '100%',
                padding: 15,
                display: 'flex',
                alignItems: 'flex-end',
                justifyContent: 'flex-end',
                
              }}
            >
              <p
                style={{
                  fontSize: 14,
                  textAlign: 'right', // Ensures the text is aligned to the right
                  textOverflow: 'ellipsis',

                  color: sourceCodeAvailable ? '#07fc03' : '#fc5e03',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  width: '100%', // Ensures it fits within the available width
                  marginTop: 'auto',

                }}
              >
                {sourceCodeAvailable ? (
                  <span className="bolden-gate" style={{ color: '#07fc03', }}>
                    {'Source Code: Available'}
                  </span>
                ) : (
                  <span className="bolden-gate" style={{ color: '#fc5e03' }}>
                    {'Source Code: Proprietary'}
                  </span>
                )}
              </p>
            </div>
          </div>
  

      </div>
    )) || (
      <div
        className={`non-highlightable ${isHovered ? 'hovered' : ''}`} // Apply the hovered class
        onMouseEnter={() => setIsHovered(true)} // Set hover state to true
        onMouseLeave={() => setIsHovered(false)} // Set hover state to false
        onClick={() => onClick ? onClick() : null}
        // className="frosted-glass-container-blur-plus-plus"
        style={{
          height: 280,
          width: '95%',
          border: isHovered ? "1px solid #98FF98" : "1px solid #6e6ef4",
          borderRadius: 15,
          ...style,
          display: "flex",
          flexDirection: "column", // Keeps children in a column
          backgroundColor: '#120922',
          background: isHovered ? 'linear-gradient(180deg, rgba(28,19,57,1) 0%, rgba(14,14,33,0.7) 52%, rgba(28,19,57,1) 94%)' : 'linear-gradient(0deg, rgba(58,19,130,.7) 0%, rgba(14,14,33,0.7) 82%, rgba(28,19,57,1) 94%)',
          boxShadow: "0 4px 20px rgba(0, 0, 0, 0.5)", // Add drop shadow
          // width: '100%', // Ensure it fills the parent container,
          margin: 15,
          cursor: 'pointer',
           color: 'white'
        }}
      >

     

          <div
            style={{height: '23%', padding: 15, display: 'flex', flexDirection: 'row'}}
          >
            <div
              style={{flex: 8, padding: 0, display: 'flex', justifyContent: 'flex-start', alignItems: 'center', overflow: 'hidden', textOverflow: 'ellipsis',}}
            > 
              <h1 className="bolden-gate" style={{paddingLeft: 15, fontSize: 26, }}>{title}</h1>
            </div>
            <div
              style={{flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center'}}
            > 
              {icon === 'react_native' && (
                <FaReact color={theme === 'dark' ? '#ccccff' : '#5a5ac9'} size={40} /> 
              )
              ||
              icon === 'react' && (
                <FaReact color={theme === 'dark' ? '#ccccff' : '#5a5ac9'} size={40} /> 
              )
              ||
              icon === 'python' && (
                <FaPython color={theme === 'dark' ? '#ccccff' : '#5a5ac9'} size={40} /> 
              )
              ||
              icon === 'java' && (
                <FaJava color={theme === 'dark' ? '#ccccff' : '#5a5ac9'} size={40} /> 
              )}
              
            </div>
          </div>

          <div
            style={{
              height: '30%',
              padding: 15,
              paddingTop: 0,
              display: 'flex',
              flexDirection: 'row',
              overflow: 'hidden',
              // border:"1px solid white"
            }}
          >
            <div
              style={{
                flex: 1,
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
              }}
            >
              <p
                style={{
                  paddingLeft: 15,
                  textAlign: 'left',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',        // This enables multi-line ellipsis
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 3,            // Limits the text to 2 lines
                  lineHeight: '1.5em',           // Adjust line height to ensure neat line spacing
                  maxHeight: '5em',              // Adjust max height to accommodate 2 lines
                  whiteSpace: 'normal',          // Allows text to wrap
                }}
              >
                {description}
              </p>
            </div>
          </div>




        <div
          style={{
            fontSize: 13,
            height: '36%',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            paddingLeft: 20,
          }}
        >
          <div
            style={{
              width: '100%',
              // border: '1px solid white',
              padding: 5,
              flexWrap: 'wrap',
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
            }}
          >
            {technologies && technologies.length > 0 && (
              <div style={{ display: 'flex', flexWrap: 'wrap', }}>
                {technologies.slice(0, 3).map((tech, index) => {
                  const techStyle = techStyles[tech.value];
                  const shadowColor = techStyle ? techStyle.color : 'white'; // Use tech color or default to white
                  return techStyle ? (
                    <div
                      key={index}
                      
                      style={{
                        display: 'flex',
                        border: techStyle.border,
                        borderRadius: 15,
                        backgroundColor: techStyle.backgroundColor,
                        color: techStyle.color,
                        boxShadow: `0 1px 10px ${shadowColor}, 0 2px 10px rgba(0, 0, 0, 0.3)`, // Shadow same as tech color,
                        alignItems: 'center',
                        lineHeight: 3,
                        justifyContent: 'center',
                        padding: '3px 10px',
                        margin: 5
                      }}
                    >
                      <p className="bolden-gate" style={{border: '0px solid white', lineHeight: 2, margin: 0}}>{tech.language}</p>
                    </div>
                  ) : (
                    <div
                      key={index}
                      className="bolden-gate"
                      style={{
                        display: 'flex',
                        border: '2px solid white',
                        borderRadius: 15,
                        backgroundColor: 'black',
                        color: 'white',
                        boxShadow: `0 1px 10px ${shadowColor}, 0 2px 10px rgba(0, 0, 0, 0.3)`, // Shadow same as tech color,
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '3px 10px',
                        margin: 5
                      }}
                    >
                      <p className="bolden-gate" style={{border: '0px solid white', lineHeight: 2, margin: 0}}>{tech.language}</p>
                    </div>
                  );
                })}
                {technologies.length > 4 && (
                  <div
                    className="bolden-gate"
                    style={{
     
                      display: 'flex',
                        border: '2px solid #6e6ef4',
                        borderRadius: 15,
                        backgroundColor: 'black',
                        color: 'white',
                        boxShadow: `0 1px 10px white`, // White shadow for the "+ x more"
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '3px 10px',
                       margin: 5
                    }}
                  >
                    <p className="bolden-gate" style={{border: '0px solid white', lineHeight: 2, margin: 0}}>+ {technologies.length - 4} more</p>
                  </div>
                )}
              </div>
            )}

          </div>
        </div>
          <div
            style={{height:"15%", display: 'flex', flexDirection: 'row', }}
          >
            <div
              style={{
                width: '100%',
                padding: 15,
                display: 'flex',
                alignItems: 'flex-end',
                justifyContent: 'flex-end',
                
              }}
            >
              <p
                style={{
                  fontSize: 14,
                  textAlign: 'right', // Ensures the text is aligned to the right
                  textOverflow: 'ellipsis',

                  color: sourceCodeAvailable ? '#07fc03' : '#fc5e03',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  width: '100%', // Ensures it fits within the available width
                }}
              >
                {sourceCodeAvailable ? (
                  <span className="bolden-gate" style={{ color: '#07fc03' }}>
                    {'Source Code: Available'}
                  </span>
                ) : (
                  <span className="bolden-gate" style={{ color: '#fc5e03' }}>
                    {'Source Code: Proprietary'}
                    {/* {'Source Code: Proprietary (Contractual Ownership)'} */}
                  </span>
                )}
              </p>
            </div>
          </div>
  

      </div>
    )
  );
}

export default ProjectItem;
