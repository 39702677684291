// AnimatedIconButton.js
import "../App.css"; // Ensure your CSS is linked
import React from 'react';

function AnimatedTextButton({ onClick, theme, text, style }) {
  const handleClick = () => {
    onClick();
  };

  return (
    <div
      onClick={handleClick}
      style={{
        ...style,
        width: 'auto',
        height: 45,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        // backgroundColor: theme === 'dark' ? '#5a5ac9' : '#ffffff',
        borderRadius: 15,
        // border: theme === 'dark' ? '2px solid #7b7bf7' : '2px solid #c4c4c4',
        paddingRight: 15,
        paddingLeft: 15,
      }}
    >
      <div
        style={{
          userSelect: 'none',
          color: theme === 'dark' ? '#ffffff' : '#5a5ac9',
          fontSize: 18, // Adjust text size as needed
        }}
      >
        {text}
      </div>

    </div>
  );
}

export default AnimatedTextButton;
